import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Elements The Awakening`}</h1>
    <p>{`Under 2012 så kommer det att komma en ny videoslot från NetEnt där dom har valt att lägga ner lite extra tid för att producera en som håller en högre standard rent grafiskt och ger spelaren en bättre spelupplevelse.`}</p>
    <p>{`Det finns idag ännu inte så mycket information om detta spel men det som man kan se från Netent egna demo video från spelet så ser det ut som att spelet kretsar kring de fem elementen. Det som ser ut att göra den är videosloten till något extra är de många imponerade animinationerna som man kan se från demo videon, normalt så finns det inte mera än en typ av wild symbol i en video slot medans i det Elements – The Awakening så har du fem olika, ett för varje element. Alla dessa har olika typer av animationer och det återstå med att se vilken som kommer att vara den absolut bästa. Detsamma gäller för freespins rundor då alla element står får en egen version av freespins rundor.`}</p>
    <p>{`Elements – The Awakening ser ut att bli en oerhört intressant video slot då den rent grafiskt är väl utformad och har en underbar spelkänsla i sig.`}</p>
    <p>{`Du kan titta på demo videon som finns ute just nu nedan.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      